

import {TrackingFeatureLabels} from "../types";

export default class Tile {
    readonly tileDom: HTMLElement;
    readonly position: number;
    readonly dreson: string | null;
    readonly urlPath: string | null;
    readonly variationId: string | null;
    readonly teslaModel: string | null;
    readonly featureTrackingLabels: TrackingFeatureLabels;

    constructor(tileDom: HTMLElement) {
        this.tileDom = tileDom;
        this.position = parseInt(tileDom.getAttribute("data-index")!) + 1;
        this.dreson = tileDom.getAttribute('data-dreson');
        this.urlPath = tileDom.getAttribute("href");
        this.variationId = tileDom.getAttribute('data-variation-id');
        this.teslaModel = tileDom.getAttribute("data-source");
        this.featureTrackingLabels = JSON.parse(tileDom.getAttribute("data-feature-tracking-tile-labels")!);
    }

    setIsTracked() {
        this.tileDom.setAttribute("data-is-tracked", String(true));
    }

    isTracked() {
        return this.tileDom.getAttribute("data-is-tracked") === "true";
    }

    setIsTrackedAsVisible() {
        this.tileDom.setAttribute("data-is-tracked-visible", String(true));
    }

    isTrackedAsVisible() {
        return this.tileDom.getAttribute("data-is-tracked-visible") === "true";
    }
}